var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ebaCommonErrorModal", { ref: "commonErrorModal" }),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "headerSection" },
        [
          _c(
            "p",
            {
              staticClass: "headerText gradientText heading",
              staticStyle: {
                "margin-bottom": "0px !important",
                "font-size": "18px !important",
              },
            },
            [
              _vm._v(
                "\n            See how banks & suppliers view your business credit score with Experian - *Free for " +
                  _vm._s(_vm.trialPeriodText) +
                  "s\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "nonLimButton",
              staticStyle: {
                "margin-bottom": "25px !important",
                "font-size": "21px !important",
              },
              attrs: { "data-v-56b687e3": "" },
            },
            [
              _vm._v(
                "\n        Review your Business Credit Score with our Credit Review Service. 96% success rate in increasing a Business Credit Score & Risk Rating.\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("ebaSearchBar", {
            ref: "searchBar",
            attrs: {
              placeholderText:
                "Enter your business name or registration number",
            },
            on: { searchClick: _vm.BusinessSearch },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isAuthenticated,
                  expression: "!isAuthenticated",
                },
              ],
              staticClass: "nonLimButton haveAccount",
            },
            [
              _vm._v(" \n            Already have an account? "),
              _c("router-link", { attrs: { to: { name: "Login" } } }, [
                _c("strong", [_vm._v("Log in")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "nonLimButton" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "NonLimitedReport" } } },
                [_vm._v("OR Find My Non-Limited Business")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gradientText",
              staticStyle: { "font-size": "18px", padding: "0 10px" },
            },
            [
              _vm._v(
                "\n            A monthly fee of " +
                  _vm._s(_vm.price) +
                  " applies after your Free Trial. You\n            may cancel during your free trial without charge.\n        "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        [
          _c("ebaLineText", {
            staticStyle: { "margin-top": "38px", "margin-bottom": "15px" },
            attrs: { text: "See how we can help you improve & grow" },
          }),
          _vm._v(" "),
          _c("ebaChevron"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "iconInfoContainer" },
            [
              _c(
                "ebaIconInfoBlock",
                {
                  staticClass: "whiteBackgroundVioletText",
                  attrs: {
                    iconSource: require("../components/common/assets/images/eye.svg"),
                  },
                },
                [
                  _vm._v(
                    "\n                View your Experian business credit score\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "ebaIconInfoBlock",
                {
                  staticClass: "whiteBackgroundVioletText middleInfoItem",
                  attrs: {
                    iconSource: require("../components/common/assets/images/dial.svg"),
                  },
                },
                [
                  _vm._v(
                    "\n                See what's affecting your score and how to improve it\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "ebaIconInfoBlock",
                {
                  staticClass: "whiteBackgroundVioletText",
                  attrs: {
                    iconSource: require("../components/common/assets/images/graph.svg"),
                  },
                },
                [
                  _vm._v(
                    "\n                Unlock new sources of finance to grow your business\n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "sectionDivider" }),
        ],
        1
      ),
      _vm._v(" "),
      _c("section", { staticClass: "signUpSection" }, [
        _c(
          "p",
          {
            staticClass: "lightBlue",
            staticStyle: {
              "text-align": "center",
              "font-size": "16px",
              "margin-bottom": "24px",
              "margin-top": "38px",
            },
          },
          [_vm._v("What\n            you'll need to sign up")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "iconInfoContainer" },
          [
            _c(
              "ebaIconInfoBlock",
              {
                staticClass: "lightBlueWhiteText",
                attrs: {
                  iconSource: require("../assets/Images/Icons/Briefcase.svg"),
                  smallIcon: true,
                },
              },
              [
                _vm._v(
                  "\n                You'll need to be an owner or director of the company you're searching for\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "ebaIconInfoBlock",
              {
                staticClass: "lightBlueWhiteText",
                attrs: {
                  iconSource: require("../assets/Images/Icons/House.svg"),
                  smallIcon: true,
                },
              },
              [
                _vm._v(
                  "\n                Your home address, phone, email and date of birth\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "ebaIconInfoBlock",
              {
                staticClass: "lightBlueWhiteText",
                attrs: {
                  iconSource: require("../assets/Images/Icons/Cards.svg"),
                  smallIcon: true,
                },
              },
              [
                _vm._v(
                  "\n                Valid debit or credit card\n            "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }